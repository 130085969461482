import './App.css';
import About from './sections/About';
import Projects from './sections/Projects';
import Experience from './sections/Experience';
import Header from './sections/Header';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";


const particlesInit = async (main) => {
  console.log(main);

  // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  // starting from v2 you can add only the features you need reducing the bundle size
  await loadFull(main);
};

const options = {
  "fullScreen": {
      "enable": true,
      "zIndex": 1
  },
  "particles": {
      "number": {
          "value": 20,
          "density": {
              "enable": false,
              "value_area": 800
          }
      },
      "color": {
          "value": "#bdd3e9"
      },
      "shape": {
          "type": "star",
          "options": {
              "sides": 5
          }
      },
      "opacity": {
          "value": 0.8,
          "random": false,
          "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
          }
      },
      "size": {
          "value": 3,
          "random": false,
          "anim": {
              "enable": false,
              "speed": 10,
              "size_min": 0.1,
              "sync": false
          }
      },
      "rotate": {
          "value": 0,
          "random": true,
          "direction": "clockwise",
          "animation": {
              "enable": true,
              "speed": 3,
              "sync": false
          }
      },
      "line_linked": {
          "enable": false,
          "distance": 600,
          "color": "#ffffff",
          "opacity": 0.4,
          "width": 2
      },
      "move": {
          "enable": true,
          "speed": 2,
          "direction": "top",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
          }
      }
  },
  "interactivity": {
      "events": {
          "onhover": {
              "enable": false,
              "mode": ["grab"]
          },
          "onclick": {
              "enable": false,
              "mode": "bubble"
          },
          "resize": true
      },
      "modes": {
          "grab": {
              "distance": 400,
              "line_linked": {
                  "opacity": 1
              }
          },
          "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
              "speed": 3
          },
          "repulse": {
              "distance": 200
          },
          "push": {
              "particles_nb": 4
          },
          "remove": {
              "particles_nb": 2
          }
      }
  },
  "retina_detect": true,
  "background": {
      "color": "#111",
      "image": "",
      "position": "50% 50%",
      "repeat": "no-repeat",
      "size": "cover",
      "opacity": 0
  }
}

function App() {
  return (
    <div className="app-container">
      <Header/>
      <About/>
      {/* <Projects/> */}
      {/* <Experience/> */}
      <Particles id="particles-here" init={particlesInit} options={options} />
    </div>
  );
}

export default App;
