const About = () => {
  return (
    <div className="">
      <h1>About Me</h1>
      <p>I am a UBC Computer Science graduate with an interest in full-stack engineering.

In my free time, I enjoy playing bass and guitar, and gaming.

{/*TODO Check out my work experience and projects below. */}
</p>
    </div>
  );
}

export default About;
