import resume from '../document.svg';
import linkedin from '../linkedin.svg';
import github from '../github.svg';

const Header = () => {
  return (
    <div className="header-buttons">
      <a href={'resume.pdf'} target="_blank" rel="noreferrer">
        <img src={resume} className='resume-icon' alt='resume'/>
      </a>
      <a href="https://www.linkedin.com/in/codysayo/" target="_blank" rel="noreferrer">
        <img src={linkedin} className='linkedin-icon' alt='linkedin'/>
      </a>
      <a href="https://www.github.com/codysayo/" target="_blank" rel="noreferrer">
        <img src={github} className='github-icon' alt='github'/>
      </a>
    </div>
  );
}

export default Header;
